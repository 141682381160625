/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  LockClosedIcon,
  LockOpenIcon,
  CheckCircleIcon,
} from '@heroicons/react/solid'
import Spinner from '../assets/spinner.svg'
import useFirebase from '../hooks/useFirebase'
import { useApp } from '../hooks/useAppContext'
import HeadLinks from '../components/HeadLinks'

export default function Login() {
  const { router } = useApp()
  const { authUser, signIn, signingIn, error, clearError } = useFirebase()
  const emailRef = useRef()
  const passwordRef = useRef()

  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const onSignInClicked = async () => {
    if (signingIn) {
      return
    }

    clearError()

    if (
      !emailRef.current.reportValidity() ||
      !passwordRef.current.reportValidity()
    ) {
      return
    }

    try {
      await signIn({
        email: emailRef.current.value,
        password: passwordRef.current.value,
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (!authUser) {
      return
    }
    router.push(router.query.returnUrl ?? '/')
  }, [router, authUser])

  const buttonContent = () => {
    if (signingIn) {
      return <Spinner className="w-5 h-5" />
    }

    if (authUser) {
      return <CheckCircleIcon className="w-5 h-5" />
    }

    return t('login.button')
  }

  return (
    <>
      <HeadLinks uri="/login" />
      <div className="flex flex-col items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
        <div className="w-full max-w-md px-8 py-8 space-y-8 bg-white border border-gray-200 rounded-3xl">
          <div>
            <img
              className="w-auto h-12 mx-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
              alt="Logo"
            />
            <h2 className="mt-6 text-4xl font-extrabold text-center text-gray-900">
              {t('login.title')} 👋
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={(e) => e.preventDefault()}>
            <div className="space-y-2 rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  E-mail
                </label>
                <input
                  ref={emailRef}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none font-body focus:outline-none focus:ring-indigo-500 focus:border-ad-blue focus:z-10 sm:text-sm"
                  placeholder="E-mail"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  ref={passwordRef}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg appearance-none font-body focus:outline-none focus:ring-indigo-500 focus:border-ad-blue focus:z-10 sm:text-sm"
                  placeholder={t('login.passwordPlaceholder')}
                />
              </div>
            </div>

            <div className="flex items-center justify-end">
              <div className="self-end text-sm">
                {error ? (
                  <p className="font-medium text-red-500">{t('login.error')}</p>
                ) : (
                  <a
                    href="mailto:oi@nichemat.es"
                    className="font-medium text-gray-600 hover:text-gray-500">
                    {t('login.forgotPassword')}
                  </a>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={onSignInClicked}
                className={cx(
                  'relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition-all border border-transparent rounded-lg disabled:bg-gray-500 group focus:outline-none focus:ring-2 focus:ring-offset-2',
                  {
                    'bg-gray-600 focus:ring-indigo-500 focus:bg-indigo-500 hover:bg-indigo-500':
                      !authUser,
                    'bg-green-500 focus:ring-green-500 focus:bg-green-500 hover:bg-green-500':
                      authUser,
                  },
                )}>
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  {authUser ? (
                    <LockOpenIcon className="w-5 h-5 text-white" />
                  ) : (
                    <LockClosedIcon
                      className={cx('w-5 h-5 group-hover:text-white', {
                        'text-gray-500': !signingIn && !authUser,
                        'text-white': signingIn || authUser,
                      })}
                      aria-hidden="true"
                    />
                  )}
                </span>
                {buttonContent()}
              </button>
              <p
                className="mt-2 text-sm text-center text-gray-600"
                dangerouslySetInnerHTML={{ __html: t('login.askForAccess') }}
              />
            </div>
          </form>
        </div>
        <div
          className="max-w-sm mt-4 text-xs text-center text-gray-500"
          dangerouslySetInnerHTML={{
            __html: t('login.termsAndPrivacyAcceptance'),
          }}
        />
      </div>
    </>
  )
}
